import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import { getTagName } from "../utils/tag-util"
import ProjectTagPageInternals from "../components/project-tag-page-internals"

const ProjectTagPage = ({ data, pageContext }) => {
    const { tag } = pageContext
    const name = getTagName(tag) + " Projects"
    return (
        <>
            <Seo title={name}/>
            <ProjectTagPageInternals data={data}
                                     tag={tag}
                                     name={name}
                                     displayCount={true}/>
        </>
    )
}

export default ProjectTagPage

export const query = graphql`
  query($tag: String!) {
    allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fields: {type: {eq: "project"}}, frontmatter: {tags: { in: [$tag]}}} 
      ) {
      totalCount
      edges {
        node {
          ...ProjectOverviewFragment
        }
      }
  }
  }
`

